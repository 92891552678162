<template>
  <div>
    <eden-overview-card :title="title"></eden-overview-card>
    <eden-information-card>
      <template slot="content">
        <div
          class="border-bottom mt-2"
          v-for="order in orderItems"
          :key="order.uuid"
        >
          <div class="is-flex is-flex-column gap-1">
            <div class="is-flex is-justify-between">
              <h5>{{ formatMealName(order.item.name) }}</h5>

              <div class="is-flex gap-1">
                <p v-if="order.refund_logs !== null">
                  <el-tag type="warning"
                    >Refunded
                    <span v-if="order.refund_logs.quantity !== order.quantity">
                      ({{ order.refund_logs.quantity }})
                    </span>
                  </el-tag>
                </p>
                <div>{{ formatPrice(order.price) }}</div>
              </div>
            </div>
            <template v-if="order.addons?.length">
              <span
                v-for="(addon, index) in order.addons"
                :key="index"
                class="is-flex is-justify-between"
              >
                <span>
                  Add-Ons: {{ addon.addon.name }} x {{ addon.quantity }},
                </span>
                <span>
                  {{ formatPrice(addon.price) }}
                </span>
              </span>
            </template>
            <p class="text-grey-tertiary">Quantity: {{ order.quantity }}</p>
          </div>
        </div>

        <div class="mt-2">
          <div
            class="is-flex is-justify-between mt-2"
            v-if="order.voucher_amount"
          >
            <div>
              <h5 class="text-medium">Discount</h5>
            </div>
            <div>{{ formatPrice(order.voucher_amount) }}</div>
          </div>
          <div
            class="is-flex is-justify-between mt-2"
            v-if="order.customer_apples_discount"
          >
            <div>
              <h5 class="text-medium">
                Apples ({{ order.apples_used_count }})
              </h5>
            </div>
            <div>{{ formatPrice(order.customer_apples_discount) }}</div>
          </div>

          <div class="is-flex is-justify-between mt-2">
            <div>
              <h5 class="text-medium">Subtotal</h5>
            </div>
            <div>{{ formatPrice(order.total_after_discount) }}</div>
          </div>

          <div class="is-flex is-justify-between mt-2">
            <div>
              <h5 class="text-medium">Delivery</h5>
            </div>
            <div>{{ formatPrice(order.delivery_fee) }}</div>
          </div>

          <div class="is-flex is-justify-between mt-2">
            <div>
              <h5>Total</h5>
            </div>
            <div class="text-bold">
              {{ formatPrice(order.total_after_discount + order.delivery_fee) }}
            </div>
          </div>
        </div>
      </template>
    </eden-information-card>
  </div>
</template>

<script>
export default {
  name: "OrderItems",
  props: {
    data: {
      type: Object,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    title() {
      return `Order Items`;
    },
    orderItems() {
      return this.data.items;
    },
    order() {
      return this.data;
    },
    totalPrice() {
      return this.orderItems?.reduce((total, item) => {
        return total + item.price;
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.information-card {
  padding: 18px 24px;
}

div {
  font-family: "CerebriSans", sans-serif;
}

h5 {
  font-size: 14px;
  color: #0f241b;
  font-family: "CerebriSans", sans-serif;
}

p {
  font-size: 12px;
  margin-bottom: 24px;
}

.border-bottom {
  border-bottom: 1px solid #f0f4f2;
}

.text-medium {
  font-weight: 400;
}
</style>
