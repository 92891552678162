import axios from "axios";

export default {
  list(params) {
    return axios.get(`marketplace_crm/orders${params}`);
  },

  index(id) {
    return axios.get(`marketplace_crm/orders/${id}`);
  },

  search(params) {
    return axios.get(`marketplace_crm/orders${params}`);
  },

  filter(params) {
    return axios.get(`marketplace_crm/orders?${params}`);
  },
  stats(params) {
    return axios.get(`marketplace_crm/orders/stats`, {
      params,
    });
  },
  export(payload) {
    return axios.post(`marketplace_crm/orders/export/all`, payload);
  },
  complete(payload) {
    return axios.post(`marketplace_crm/orders/complete`, payload);
  },
  logistics(payload) {
    return axios.post(
      `marketplace_crm/orders/send_to_logistics_partner`,
      payload,
    );
  },
  cancel(payload) {
    return axios.post(`marketplace_crm/orders/cancel`, payload);
  },
};
