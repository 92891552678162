<template>
  <div>
    <eden-overview-card :title="title"></eden-overview-card>
    <eden-information-card>
      <template slot="content">
        <div class="">
          <div>
            <p class="text-grey-tertiary mb-0">Name</p>
            <p>{{ recipientDetails?.customer_name }}</p>
          </div>

          <div>
            <p class="text-grey-tertiary mb-0">Phone Number</p>
            <p>{{ recipientDetails?.customer_phone }}</p>
          </div>

          <div>
            <p class="text-grey-tertiary mb-0">Delivery Address</p>
            <p>{{ orderDelivery?.address }}</p>
          </div>
          <div>
            <p class="text-grey-tertiary mb-0">Landmark</p>
            <p>{{ orderDelivery?.landmark }}</p>
          </div>

          <div>
            <p class="text-grey-tertiary mb-0">Delivery Area</p>
            <p>{{ orderDelivery?.delivery_area }}</p>
          </div>

          <div>
            <p class="text-grey-tertiary mb-0">Order Type</p>
            <p>
              {{
                order?.delivery_window === "on_demand"
                  ? "Instant Order"
                  : "Pre Order"
              }}
            </p>
          </div>

          <div>
            <p class="text-grey-tertiary mb-0">Order Date</p>
            <p class="mb-0">{{ formatDate(order.order_date, "do m, y") }}</p>
          </div>
        </div>
      </template>
    </eden-information-card>
  </div>
</template>

<script>
export default {
  name: "OrderRecipient",
  props: {
    data: {
      type: Object,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    title() {
      return `Recipient Information`;
    },
    recipientDetails() {
      return this.data?.recipient;
    },
    order() {
      return this.data;
    },
    orderDelivery() {
      return this.data.delivery_address;
    },
  },
};
</script>

<style lang="scss" scoped>
.information-card {
  padding: 18px 24px;
}

div {
  font-family: "CerebriSans", sans-serif;
}

p {
  font-size: 14px;
  margin-bottom: 24px;
}
</style>
