<template>
  <eden-confirm-dialog
    :title="'Process This Refund?'"
    :button-text="'Process Refund'"
    :button-type="'success'"
    :button-status.sync="confirming"
    :show.sync="setShow"
    @confirm="confirm"
  >
    <p>
      The refund total will be subtracted from the vendor’s payout amount and
      refunded to the customer’s wallet.
    </p>
  </eden-confirm-dialog>
</template>

<script>
export default {
  name: "RefundConfirm",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    confirming: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    close() {
      this.setShow = false;
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>
