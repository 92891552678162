<template>
  <div>
    <eden-overview-card :title="title"></eden-overview-card>
    <eden-information-card>
      <template slot="content">
        <div
          class="is-flex is-flex-column gap-2"
          v-if="order.status !== 'Accepted'"
        >
          <div v-if="logistics?.driver_name">
            <p class="text-grey-tertiary mb-0">Rider Name</p>
            <p>{{ logistics?.driver_name }}</p>
          </div>

          <div v-if="logistics?.driver_name">
            <p class="text-grey-tertiary mb-0">Rider Phone Number</p>
            <p>{{ logistics?.driver_phone }}</p>
          </div>

          <div>
            <p class="text-grey-tertiary mb-0">Delivery Partner</p>
            <p class="mb-0">{{ logistics?.logistics_partner }}</p>
          </div>

          <div v-if="logistics?.logistics_partner_otps.length">
            <p class="text-grey-tertiary mb-0">Delivery Partner OTPs</p>
            <p
              class="mb-0"
              v-for="(logistics, idx) in logistics?.logistics_partner_otps"
              :key="idx"
            >
              {{ formatText(logistics.task) }}: {{ logistics.otp }}
            </p>
          </div>
        </div>
        <div v-else>No rider has been assigned to this order</div>
      </template>
    </eden-information-card>
  </div>
</template>

<script>
export default {
  name: "OrderDelivery",
  props: {
    data: {
      type: Object,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    title() {
      return `Delivery`;
    },
    order() {
      return this.data;
    },
    logistics() {
      return this.data.logistics_tracker;
    },
  },
};
</script>

<style lang="scss" scoped>
.information-card {
  padding: 18px 24px;
}

div {
  font-family: "CerebriSans", sans-serif;
}

p {
  font-size: 14px;
  margin-bottom: 24px;
}
</style>
