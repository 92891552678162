var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"individual-vendor-details"},[(_vm.loading)?_c('div',[_vm._v("loading")]):[_c('eden-page-header',{attrs:{"title":'Orders & Schedule',"subtitle":_vm.customerOrderNumber}})],[_c('div',{staticClass:"order"},[_c('div',{staticClass:"is-flex is-flex-column"},[_c('eden-information-card',[_c('template',{slot:"content"},[_c('div',{staticClass:"order-summary"},[_c('div',{staticClass:"order-summary__image"},[_c('img',{attrs:{"src":_vm.getImage('meal.svg'),"alt":"service"}})]),_c('div',{staticClass:"order-summary__details"},[_c('h6',[_vm._v("#"+_vm._s(_vm.orderDetails.order_number))]),_c('p',{staticClass:"text-bold"},[_vm._v(" "+_vm._s(_vm.orderDetails?.recipient?.customer_name)+" ")]),_c('div',{staticClass:"is-flex is-justify-center mt-1 gap-1"},[_c('p',[_c('el-tag',{attrs:{"type":_vm.setType(_vm.orderDetails.status)}},[_vm._v(_vm._s(_vm.formatText(_vm.orderDetails.status)))])],1),_c('p',[_c('el-tag',{attrs:{"type":_vm.setType(_vm.orderDetails.delivery_window)}},[_vm._v(" "+_vm._s(_vm.orderDetails?.delivery_window === "on_demand" ? "Instant Order" : "Pre Order"))])],1)]),_c('div',{staticClass:"is-flex justify-center my-2"},[(_vm.allowAccessFor(['vendor_manager', 'admin']))?_c('el-button',{attrs:{"type":"plain","size":"medium","disabled":_vm.isLogRefundDisabled},on:{"click":function($event){_vm.showOperationDetails = true}}},[_vm._v("Log refund")]):_vm._e()],1)])])])],2),_c('eden-information-card',{attrs:{"title":"General Info"}},[_c('template',{slot:"content"},[_c('div',{staticClass:"is-flex is-flex-column is-justify-between gap-1"},[_c('div',{staticClass:"border-bottom py-2 px-2"},[_c('p',{staticClass:"general-header"},[_vm._v("Vendor Name")]),_c('router-link',{attrs:{"to":{
                    name: 'vendors.details',
                    params: { id: _vm.orderDetails.vendor_uuid },
                  }}},[_c('span',{staticClass:"font-base text-medium text-primary mt-1"},[_vm._v(" "+_vm._s(_vm.formatName(_vm.orderDetails.vendor_name)))])])],1),_c('div',{staticClass:"border-bottom py-2 px-2"},[_c('p',{staticClass:"general-header"},[_vm._v("Account Manager")]),_c('p',{staticClass:"font-base text-base mt-1"},[_vm._v(" "+_vm._s(_vm.formatName(_vm.orderDetails?.account_manager_name))+" ")])])])])],2),(_vm.orderDetails?.refund_details !== null)?_c('eden-information-card',{attrs:{"title":"Refund Logged"}},[_c('template',{slot:"content"},[_c('div',{staticClass:"is-flex is-flex-column is-justify-between gap-1"},[_c('div',{staticClass:"border-bottom py-2 px-2"},[_c('p',{staticClass:"general-header"},[_vm._v("Items Refunded For")]),_c('p',{staticClass:"font-base text-medium mt-1"},[_vm._v(" "+_vm._s(_vm.orderDetails?.refund_details?.refund_quantity)+" item(s) ")])]),_c('div',{staticClass:"border-bottom py-2 px-2"},[_c('p',{staticClass:"general-header"},[_vm._v("Refund Logged By")]),_c('p',{staticClass:"font-base text-base mt-1"},[_vm._v(" "+_vm._s(_vm.formatName(_vm.orderDetails?.refund_details?.refund_by?.name))+" ")])])])])],2):_vm._e(),_c('eden-information-card',{attrs:{"title":"Order Timeline"}},[_c('template',{slot:"content"},[_c('div',{staticClass:"is-flex is-flex-column is-justify-between gap-1"},[_c('div',{staticClass:"border-bottom py-2 px-2"},[_c('p',{staticClass:"general-header"},[_vm._v("Order Placed")]),_c('p',{staticClass:"font-base text-base"},[_vm._v(" "+_vm._s(_vm.formatTime(_vm.orderDetails?.order_date, "12h"))+" ")])]),(
                  _vm.orderDetails.status === 'Accepted' ||
                  _vm.orderDetails.status === 'Enroute pickup' ||
                  _vm.orderDetails.status === 'Dispatched' ||
                  _vm.orderDetails.status === 'Completed'
                )?_c('div',{staticClass:"border-bottom py-2 px-2"},[_c('p',{staticClass:"general-header"},[_vm._v("Order Accepted")]),_c('p',{staticClass:"font-base text-base"},[_vm._v(" "+_vm._s(_vm.formatTime(_vm.orderDetails?.accepted_at, "12h"))+" ")])]):_vm._e(),(
                  _vm.orderDetails.status === 'Dispatched' ||
                  _vm.orderDetails.status === 'Completed'
                )?_c('div',{staticClass:"border-bottom py-2 px-2"},[_c('p',{staticClass:"general-header"},[_vm._v("Order Picked Up")]),_c('p',{staticClass:"font-base text-base"},[_vm._v(" "+_vm._s(_vm.formatTime(_vm.orderDetails?.dispatched_at, "12h"))+" ")])]):_vm._e(),(_vm.orderDetails.status === 'Completed')?_c('div',{staticClass:"border-bottom py-2 px-2"},[_c('p',{staticClass:"general-header"},[_vm._v("Order Delivered")]),_c('p',{staticClass:"font-base text-base"},[_vm._v(" "+_vm._s(_vm.formatTime(_vm.orderDetails?.completed_at, "12h"))+" ")])]):_vm._e()])])],2)],1),_c('div',{staticClass:"order-body"},[_c('div',{staticClass:"detail"},[_c('el-tabs',{on:{"tab-click":_vm.updateRouteQuery},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('el-tab-pane',{attrs:{"label":"Order","name":"order"}},[_c('OrderItems',{attrs:{"data":_vm.orderDetails}}),(
                  _vm.orderDetails.status !== 'Pending' &&
                  _vm.orderDetails.status !== 'Rejected'
                )?_c('OrderDelivery',{attrs:{"data":_vm.orderDetails}}):_vm._e(),_c('OrderRecipient',{attrs:{"data":_vm.orderDetails}}),(_vm.orderDetails.status === 'Cancelled')?_c('div',[_c('eden-overview-card',{attrs:{"title":"Reason for cancellation"}}),_c('eden-information-card',{staticClass:"cancellation-card"},[_c('template',{slot:"content"},[_c('div',{},[_vm._v(" "+_vm._s(_vm.orderDetails.reason_for_cancellation)+" ")])])],2)],1):_vm._e()],1)],1)],1)])])],_c('LogRefund',{attrs:{"show":_vm.showOperationDetails,"data":_vm.orderDetails},on:{"update:show":function($event){_vm.showOperationDetails=$event},"success":_vm.getOrderDetails}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }