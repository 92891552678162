<template>
  <section>
    <el-dialog :title="`Log Refund`" :visible="shouldShow" @close="closeModal">
      <el-form :model="form" label-position="top">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Select the items in this order that were unfulfilled and should be refunded."
            >
              <section
                v-for="order in items"
                :key="order.uuid"
                class="border-bottom py-2 border-grey-septenary"
              >
                <div class="is-flex is-justify-between mt-2">
                  <div class="is-flex gap-1">
                    <el-checkbox
                      v-model="form.checked"
                      :value="order"
                      :label="order"
                      >{{
                    }}</el-checkbox>
                    <div class="is-flex is-flex-column">
                      <p class="text-bold font-md">
                        {{ formatMealName(order.item.name) }}
                      </p>
                      <template v-if="order.addons?.length">
                        <span
                          v-for="(addon, index) in order.addons"
                          :key="index"
                        >
                          Add-Ons: {{ addon.addon.name }} x
                          {{ addon.quantity }},
                        </span>
                      </template>
                      <p class="text-grey-tertiary">
                        Quantity: {{ order.quantity }}
                      </p>
                    </div>
                  </div>
                  <div>{{ formatPrice(order.price) }}</div>
                </div>
                <el-form-item
                  v-if="isOrderCheckedAndQuantityMoreThanOne(order)"
                  label="Quantity to be refunded"
                  class="w-50"
                >
                  <div class="counter">
                    <el-button
                      :type="'control'"
                      icon="el-icon-minus"
                      :disabled="order.quantity <= 1"
                      @click="decreaseQuantity(order)"
                    />
                    <el-input
                      v-model.number="order.quantity"
                      v-number
                      type="text"
                    />
                    <el-button
                      :type="'control'"
                      icon="el-icon-plus"
                      :disabled="order.quantity >= getOriginalQuantity(order)"
                      @click="increaseQuantity(order)"
                    />
                  </div>
                </el-form-item>
              </section>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="plain" @click="closeModal"> Cancel </el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="showrefundConfirm"
          :disabled="isDisabled"
        >
          Log Refund
        </el-button>
      </span>
    </el-dialog>

    <RefundConfirm
      :show.sync="showConfirm"
      @confirm="update"
      :confirming="loading"
    />
  </section>
</template>

<script>
import details from "@/requests/vendors/details.js";
import RefundConfirm from "./RefundConfirm.vue";
export default {
  components: {
    RefundConfirm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      default: () => ({}),
    },
  },

  data() {
    return {
      form: {
        checked: [],
        originalQuantities: {},
      },
      items: [],
      loading: false,
      showConfirm: false,
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    isDisabled() {
      return !this.form.checked.length;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form = {
          checked: [],
        };
        this.items = this.data.items;
        this.$emit("success");
        this.initOriginalQuantities();
      }
    },
  },

  methods: {
    closeModal() {
      this.shouldShow = false;
    },
    initOriginalQuantities() {
      this.form.originalQuantities = this.data.items.reduce((acc, item) => {
        acc[item.uuid] = item.quantity;
        return acc;
      }, {});
    },
    getOriginalQuantity(order) {
      return this.form.originalQuantities[order.uuid];
    },
    isOrderCheckedAndQuantityMoreThanOne(order) {
      const originalQuantity = this.getOriginalQuantity(order);
      return (
        this.form.checked.some(
          (checkedOrder) => checkedOrder.uuid === order.uuid,
        ) && originalQuantity > 1
      );
    },
    decreaseQuantity(order) {
      if (order.quantity > 1) {
        order.quantity--;
      }
    },
    increaseQuantity(order) {
      const originalQuantity = this.getOriginalQuantity(order);
      if (order.quantity < originalQuantity) {
        order.quantity++;
      }
    },

    update() {
      this.loading = true;
      const payload = {
        order_items: this.form.checked.map((order) => {
          return {
            uuid: order.uuid,
            quantity: order.quantity,
          };
        }),
      };

      details
        .refund(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.loading = false;
            this.showConfirm = false;
            this.$emit("success");
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
      return true;
    },
    showrefundConfirm() {
      this.shouldShow = false;
      this.showConfirm = true;
    },
  },
};
</script>

<style scoped>
p {
  font-weight: 400;
  font-size: 14px;
}

.radio-box {
  @media (max-width: 820px) {
    flex-wrap: wrap !important;
  }
}

.counter {
  display: flex;
  align-items: center;
  width: 100%;

  .el-input {
    margin: 0 10px 0 0;

    &__inner {
      height: 28px;
      line-height: 28px;
      text-align: center;
    }
  }
}
</style>
